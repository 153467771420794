.page-wrapper {
  /* bottom padding 0 */
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  gap: 40px;
}

header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

header .options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media only screen and (max-width: 992px) {
  header {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
}

.input-wrapper {
  display: flex;
  gap: 40px;
  position: relative;
}

button {
  color: #000;
  position: absolute;
  outline: none;
  border: none;
  background: #fff;
  cursor: pointer;
  bottom: 3px;
  user-select: none;
}

button:last-child {
  right: 0;
}

input {
  width: 12rem;
  border: none;
  border-bottom: 3px solid #000;
  outline: none;
  border-radius: 0;
  text-align: center;
  padding-left: 12px;
}

@media only screen and (max-width: 992px) {
  .input-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  input {
    padding: 0;
  }
}

.me,
.me-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.me-wrapper {
  width: 200px;
  height: 200px;
  align-self: center;
  overflow: hidden;
}

.me {
  width: 200px;
  height: 200px;
  border: 3px solid #000;
  background-color: #fff;
  border-radius: 50%;
  text-transform: uppercase;
  flex-shrink: 0;
  transform: translateY(50px);
}

@media only screen and (max-width: 992px) {
  .me {
    width: 150px;
    height: 150px;
    transform: translateY(60px);
  }
}

.crowd {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 80%;
  user-select: none;
}

.crowd img {
  height: 160px;
}

.crowd .person {
  height: 40px;
}

@media only screen and (max-width: 992px) {
  .crowd {
    width: 100%;
    gap: 8px;
  }

  .crowd img {
    height: 120px;
  }

  .crowd .person {
    height: 60px;
  }
}
